
    .react-calendar {
      font-family: inherit, Arial, Helvetica, sans-serif;
    }
  
  .react-calendar--doubleView {
    width: 700px;
  }
  
  .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
  }
  
  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
  }
  
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  .react-calendar__navigation {
    display: flex;
    height: 44px;
    color: theme('colors.primary.500');
    font-size: large;
    margin-bottom: 1em;
  }
  
  .react-calendar__navigation button {
    min-width: 44px;
    background: none;
  }
  
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: theme('colors.neutral.200');
  }
  
  .react-calendar__month-view__days__day--neighboringMonth {
    color: theme('colors.neutral.300');
  }

  abbr[title] {
    text-decoration: none;
    font-weight: bold;
    color: theme('colors.neutral.500')
   }

  .react-calendar__tile {
    max-width: 100%;
    padding: 10px 10px;
    background: none;
    border-radius: 25px;
    text-align: center;
    line-height: 16px;
    font: inherit;
    font-size: 0.833em;
  }
  
  .react-calendar__tile--now {
    background: theme('colors.primary.300');
    font-weight: bold;
    color: theme('colors.primary.700');
  }
  
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: #f8f8fa;
 color: #6f48eb;
  }
  
  .react-calendar__tile--active {
    background: theme('colors.primary.500');
    font-weight: bold;
    color: theme('colors.neutral.50');
  }
  
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: theme('colors.primary.800');
    color: theme('colors.neutral.50');
  }
  